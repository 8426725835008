.table {
    border: none;
    border-collapse: collapse;
    border: 1px solid #132347;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

th,
td {
    padding: 1.3rem 1rem !important;
    font-size: 1rem;
}
thead{
    background: #7865ff;
    color: #fff;
}
td{
    font-size: 0.9rem;
    color: #fff;
    font-weight: 400;
}
tr:nth-child(even) {
    background: #102048;
}

@media screen and (max-width: 992px) {
    th, td{
        font-size: 0.7rem;
        padding: 0.8rem;
    }
}