
.skills_wrapper{
    background: #132347;
    padding: 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.skill_bar {
    width: 100%;
    height: 5px;
    position: relative;
    background: #c1ceff;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    z-index: 999;
}

.skill_bar_percentage {
    position: absolute;
    height: 5px;
    top: 0;
    left: 0;
    background: #7865ff;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}
.skill_title {
    margin-bottom: 10px;
}
.skill_title span{
    color: #fff;
}
@media screen and (max-width: 992px) {
    .skill_title h6 {
        font-size: 0.8rem;
    }
    .skill_title span {
        font-size: 0.7rem;
    }
}