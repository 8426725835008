/* Base Stryle */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  background: #0E1B31;
}
html{
  scroll-behavior: smooth;
}
h1, h2, h3, h4, h5, h6 {
  color: #fff;
  margin: 0;
}
h1, h2{
  font-size: 2rem;
  font-weight: 500;
}
p {
  color: #fff;
  opacity: 75%;
  font-size: 1rem;
}
ul {
  list-style: none;
  margin: 0;
}

a{
  text-decoration: none;
  color: unset;
}
a:hover{
  color: unset;
}
section, footer {
  padding: 60px 0px;
}

/* custom scroll bar */
::-webkit-scrollbar {
  width: 10px;
  background: #c1ceff;
}
::-webkit-scrollbar-thumb {
  border-radius: 2em;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -ms-border-radius: 2em;
  -o-border-radius: 2em;
  background: #282f4e;
}

@media screen and (max-width: 992px) {
  section{
    padding: 40px 0;
  }
}