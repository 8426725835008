
.header{
    width: 100%;
    height: 70px;
    line-height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.logo h5{
    font-size: 1.3rem;
    color: #7865FF;
}

.nav__list {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
}

.nav__item a{
    color: #fff;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    cursor: pointer;
}

.nav__item a:hover{
    color: #7865FF;
}

.btn {
    background: #7865FF;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 0.9rem;
    padding: 7px 25px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}

.btn:hover{
    background: #7865FF;
    color: #fff;
    opacity: 0.8;
}

.mobile__menu i {
    color: #fff;
    font-size: 1.3rem;
    cursor: pointer;
}

.mobile__menu {
    display: none;
}

.header_shrink {
    background: #132347;
    box-shadow: 2px 2px 4px -2px #7765ffbd;
}

@media screen and (max-width: 992px) {
    .nav__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #7765ffbd;
        z-index: 99999;
        display: none;
    }

    .nav__list {
        position: absolute;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        background: #132347;
        z-index: 999999;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
    }

    .menu__active {
        display: block;
    }

    .mobile__menu {
        display: block;
    }

    .btn {
        display: none;
    }
}