.single_award {
    background: #132347;
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-bottom: 1px solid #7865ff;
}
.award_item {
    width: 48%;
    margin-bottom: 25px;
}
.award_title span {
    font-weight: 300 !important;
    color: #fff;
    opacity: 70% !important;
    font-size: 0.8rem;
}
.award_year {
    color: #fff;
    margin-bottom: 15px;
}
@media screen and (max-width: 992px) {
    .award_title {
        font-size: 0.8rem;
    }
    .award_title span {
        font-size: 0.7rem;
    }
    .award_year {
        font-size: 0.7rem;
    }
}