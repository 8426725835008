.single_info_box {
    background: #132347;
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-bottom: 1px solid #7865ff;
    margin-bottom: 40px;
}

.single_info_box p {
    font-weight: 300;
    margin-top: 10px;
    font-size: 0.9rem;
    margin-bottom: 0;
}
.single_info_box h6 {
    font-size: 1.1rem;
}

@media screen and (max-width: 992px) {
    .single_info_box h6 {
        font-size: 0.8rem;
    }
    .single_info_box p {
        font-size: 0.7rem;
    }
}