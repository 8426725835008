.about_btns {
    background: #102048;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.about_btn {
    border: none;
    outline: none;
    width: 100%;
    padding: 20px;
    background: transparent;
    color: #fff;
    border-bottom: 1px solid #7865ff;
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    -o-border-radius: 5px 5px 0 0;
}

.about_btn-active{
    background: #7865ff;
}

.about_btn:last-child {
    border-bottom: none;
    border-radius: 0 0 5px 5px;
    -webkit-border-radius: 0 0 5px 5px;
    -moz-border-radius: 0 0 5px 5px;
    -ms-border-radius: 0 0 5px 5px;
    -o-border-radius: 0 0 5px 5px;
}
.about_content_wrapper {
    background: #132347;
    padding: 30px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.about_content h2{
    margin-bottom: 15px;
}
.about_content p{
    line-height: 30px;
    font-weight: 300;
}
.social_links span a i {
    color: #7865ff;
}
.about_img{
    background: #7865ff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    height: 160px;
}
.social_links span {
    margin-right: 15px;
}

@media screen and (max-width: 1200px) {
    .about_img{
        height: 130px;
    }
}

@media screen and (max-width: 992px) {
    .about_btn{
        padding: 10px 25px;
        font-size: 0.8rem;
    }
    .about_btns {
        margin-bottom: 40px;
    }
    .about_content h2 {
        font-size: 1.5rem;
    }
    .about_content p {
        font-size: 0.8rem;
        line-height: 28px;
    }
    .about_img {
        height: 102px;
    }
}
@media screen and (max-width: 768px) {
    .about_img {
        height: 90px;
    }
}
@media screen and (max-width: 500px) {
    .about_img {
        height: 50px;
    }
}