.portfolio_top h6{
    color: #7865ff;
    margin-bottom: 15px;
}

.portfolio_card {
    position: relative;
    z-index: 90;
    overflow: hidden;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}
.portfolio_content{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgb(0, 13, 107, 0.6), rgb(0, 13, 107, 0.6));
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 91;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    opacity: 0;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    
}
.portfolio_card:hover .portfolio_content {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
}
.portfolio_content a {
    color: #fff;
    text-decoration: none;
    font-size: 0.9rem;
    cursor: pointer;
}
.portfolio_content h5 {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 10px;
    font-weight: 500;
}
.portfolio_img img {
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}