

.hire__btn, .hire__btn:hover {
    background: #fff;
    color: #0E1B31;
}

.hire__btn a{
    font-weight: 500;
}

.hire__btn:hover a {
    color: #0E1B31;
}

.hero__title {
    font-weight: 600;
}

.hero__title span {
    color: #7865ff;
}

.hero__img {
    width: 400px;
    height: 400px;
    background: #7865ff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-left: auto;
}

.hero__section{
    padding-top: 150px;
}
.hero__title {
    /* display: grid;
    grid-template-columns: 2fr 5fr; */
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
}
span.Typewriter__wrapper {
    position: relative;
    top: 1px;
}
.hero__content p {
    line-height: 30px;
    font-weight: 300;
}

@media screen and (max-width: 992px) {
    .hero__img {
        width: 300px;
        height: 300px;
    }

    .hero__content p {
        font-size: 0.8rem;
    }
    .hero__btns button {
        display: block;
        padding: 5px 20px;
        font-size: 0.8rem;
    }
    .hero__img {
        margin: auto;
    }
    .hero__content {
        margin-bottom: 50px;
    }
    .hero__img {
        width: 450px;
        height: 450px;
    }
}

@media screen and (max-width: 576px) {
    
    
    .hero__title {
        font-size: 1.5rem;
    }
    .hero__img {
        width: 300px;
        height: 300px;
    }
}