.single_experience-container {
    padding: 30px;
    background: #132347;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.single_experience {
    position: relative;
    padding: 10px 30px;
    z-index: 79;
}

.single_experience::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px ;
    height: 100%;
    background: #7865ff;
    color: #7865ff;
    z-index: 80;
}
.experience_icon {
    position: absolute;
    top: 5px ;
    left: -9px;
    color: #7865ff;
    font-size: 1.2rem;
    z-index: 81;
}
.single_experience h6{
    font-size: 0.8rem;
    opacity: 75%;
    font-weight: 300;
    margin-bottom: 10px;
}
.single_experience h5 {
    font-size: 1.1rem;
    margin-bottom: 15px;
}
.single_experience p {
    font-size: 300;
    line-height: 30px;
    font-size: 0.9rem;
}

@media screen and (max-width: 992px) {
    .single_experience h6 {
        font-size: 0.7rem;
    }
    .single_experience h5{
        font-size: 0.9rem;
        margin-bottom: 10px;
    }
    .single_experience p {
        font-size: 0.7rem;
        line-height: 28px;
    }
    .single_experience-container {
        margin-bottom: 40px;
    }
}