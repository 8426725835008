.form {
    width: 100%;
    background: #132347;
    padding: 30px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.form_input input ,
.form_input textarea {
    width: 100%;
    padding: 10px 15px;
    color: #fff;
    margin-bottom: 15px;
    background: #102048;
    border: none;
    outline: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-size: 0.8rem;
    resize: none;
}

.submit_btn {
    margin-top: 15px;
    padding: 7px 25px;
    font-size: 0.9rem;
    background: #7865ff;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
@media screen and (max-width: 992px) {
    .submit_btn {
        padding: 5px 25px;
        font-size: 0.8rem;
    }
}